import i18next from "i18next";
import { cloneDeep } from "lodash";
import { BsInstagram, BsMicrosoftTeams } from "react-icons/bs";
import {
  FaAndroid,
  FaApple,
  FaChrome,
  FaDesktop,
  FaEdge,
  FaFirefox,
  FaGlobeAmericas,
  FaInternetExplorer,
  FaLinux,
  FaMobileAlt,
  FaOpera,
  FaSafari,
  FaWhatsapp,
  FaWindows,
} from "react-icons/fa";
import { Badge } from "reactstrap";

import { Box } from "@mui/material";

import JoinedMessage from "~components/Generic/PRChatMessage/InlineMessages/NotificationMessage/JoinedMessage";
import TicketCreatedMessage from "~components/Generic/PRChatMessage/InlineMessages/NotificationMessage/TicketCreatedMessage";
import { chatbotSessionDashboardStatus, chatbotSessionPlatform, chatbotSessionStatus } from "~constants";
import DateHelper from "~helpers/DateHelper";

export function getBadgeColorBySender(sender) {
  switch (sender) {
    case "AGT":
    case "AGENT":
      return "primary";
    case "BOT":
      return "secondary";
    case "CUS":
    case "CUSTOMER":
      return "success-600";
    case "SYS":
      return "dark";
    default:
      return "secondary";
  }
}
export function getBadgeTextBySender(sender) {
  switch (sender) {
    case "AGT":
    case "AGENT":
      return "You";
    case "BOT":
      return "Bot";
    case "CUSTOMER":
    case "CUS":
      return "Customer";
    case "SYS":
      return "System";
    default:
      return "Customer";
  }
}
export function getColorByIndex(index) {
  const classList = [
    "bg-success-600 text-success-600",
    "bg-info text-info",
    "bg-danger text-danger",
    "bg-warning text-warning",
    "bg-pink text-pink",
  ];

  return classList[index % 5];
}
export function getStatusTextDashboard(status, className) {
  const { t } = i18next;
  let statusText = "";
  let statusColor = "";
  let textColor = "text-white";
  if (status === chatbotSessionDashboardStatus.QUEUE) {
    statusText = t("utils.getStatusTextDashboard.waiting");
    statusColor = "pink";
  } else if (status === chatbotSessionDashboardStatus.INPROGRESS) {
    statusText = t("utils.getStatusTextDashboard.chatting");
    statusColor = "primary";
  } else if (status === chatbotSessionDashboardStatus.CLOSED) {
    statusText = t("utils.getStatusTextDashboard.closed");
    statusColor = "secondary";
  } else if (status === chatbotSessionDashboardStatus.FAIL) {
    statusText = t("utils.getStatusTextDashboard.fail");
    statusColor = "danger";
  }
  return (
    <Badge className={`d-flex align-items-center ${className}`} color={statusColor}>
      <span className={textColor}>{statusText}</span>
    </Badge>
  );
}
export function getStatusText(status, className, suffixText) {
  const { t } = i18next;
  let statusText = "";
  let statusColor = "";
  let textColor = "text-white";
  if (status === chatbotSessionStatus.AGENT_STARTED) {
    statusText = t("utils.getStatusText.agentStarted");
    statusColor = "orange";
  } else if (status === chatbotSessionStatus.AGENT_CHAT) {
    statusText = t("utils.getStatusText.agentChat");
    statusColor = "success-600";
  } else if (status === chatbotSessionStatus.AGENT_WAIT || status === chatbotSessionStatus.BOT_CHAT_AGENT_WAIT) {
    statusText = t("utils.getStatusText.agentWait");
    statusColor = "pink";
  } else if (status === chatbotSessionStatus.BOT_CHAT) {
    statusText = t("utils.getStatusText.botChat");
    statusColor = "primary";
  } else if (status === chatbotSessionStatus.BOT_JOINED) {
    statusText = t("utils.getStatusText.botJoined");
    statusColor = "primary";
  } else if (status === chatbotSessionStatus.BOT_WAIT) {
    statusText = t("utils.getStatusText.botWait");
    statusColor = "purple";
  } else if (status === chatbotSessionStatus.CLOSED) {
    statusText = t("utils.getStatusText.closed");
    statusColor = "danger";
  } else if (status === chatbotSessionStatus.CUSTOMER_DISCONNECT) {
    statusText = t("utils.getStatusText.customerDisconnect");
    statusColor = "danger";
  } else if (status === chatbotSessionStatus.FEEDBACK) {
    statusText = t("utils.getStatusText.feedback");
    statusColor = "danger";
  } else if (status === chatbotSessionStatus.TICKET) {
    statusText = t("utils.getStatusText.ticket");
    statusColor = "danger";
  } else if (status === chatbotSessionStatus.TIMEOUT) {
    statusText = t("utils.getStatusText.timeout");
    statusColor = "warning";
    textColor = "text-dark";
  } else if (status === chatbotSessionStatus.AGENT_MISS) {
    statusText = t("utils.getStatusText.agentMiss");
    statusColor = "warning";
    textColor = "text-dark";
  } else if (status === chatbotSessionStatus.BOT_MISS) {
    statusText = t("utils.getStatusText.botMiss");
    statusColor = "warning";
    textColor = "text-dark";
  } else if (status === chatbotSessionStatus.COMPLETED) {
    statusText = t("utils.getStatusText.completed");
    statusColor = "success-600";
  } else if (status === chatbotSessionStatus.FAILED) {
    statusText = t("utils.getStatusText.failed");
    statusColor = "danger";
  } else if (status === chatbotSessionStatus.NEW) {
    statusText = t("utils.getStatusText.new");
    statusColor = "primary";
  } else if (status === chatbotSessionStatus.TOKEN) {
    // statusText = "Tokenization";
    // statusColor = "danger";
    statusText = t("utils.getStatusText.token");
    statusColor = "primary";
  }
  return (
    <Box
      alignItems={"center"}
      className={className}
      display={"flex"}
      sx={{
        fontSize: 12,
        color: "rgba(0, 0, 0, 0.85)",

        div: {
          backgroundColor: `var(--bs-${statusColor})`,
          width: 12,
          height: 12,
          borderRadius: "50%",
          marginLeft: "5px",
        },
      }}
    >
      {statusText}
      {suffixText && ` (${suffixText})`}
      <div></div>
    </Box>
  );
}

/**
 * @param {any} m Message
 * @param {any} previousMsg Previous Message
 * @returns
 */

let activeSenderData = {};
export function convertSocketMsgToChatMessage(m, previousMsg, session) {
  const messages = session?.messages || [];
  const senderType = m.payload?.sender_type || m.sender_type;
  const isCustomer = ["CUS", "CUSTOMER"].includes(senderType);
  const messageIndex = messages.findIndex((msg) => msg === m);
  // const nextMsg = messages[messageIndex + 1];

  const likeDislikeInfo = messages.find(
    (msg) => msg.payload?.type === "message_feedback" && msg.payload?.payload?.message_id === m.payload?.id
  );

  let payload = {
    id: m.payload?.id,
    position: isCustomer ? "left" : "right",
    isCustomer: isCustomer,
    messageTime: DateHelper.getDateTime(m.created || m.time),
    disconnected: m.payload?.disconnected,
    senderType: senderType,
    sender: senderType, //TODO: Check if it is necessary 'senderType' or 'sender'
    format: m.payload?.format?.toLowerCase?.(),
    isSent: m.isSent !== false,
    likeStatus: m.payload?.like_status ?? likeDislikeInfo?.payload?.payload?.value,
    senderData: cloneDeep(activeSenderData),
    historyId: m.historyId,
    isRag: m.payload?.is_rag,
    trendyolMsg: {
      modifiedMessageId: m.payload?.modified_message_id,
      platformMessageId: m.payload?.platform_message_id,
      suggestedMessage: m.payload?.suggested_message,
      deliveryStatus: m.payload?.delivery_status,
      productImage: m.payload?.product_image,
      productName: m.payload?.product_name,
      productUrl: m.payload?.product_url,
      historyId: m.historyId,
    },
  };
  if (m.type === "message") {
    const messageType = m.payload?.type?.toLowerCase();

    if (messageType === "text") {
      payload = {
        ...payload,
        type: "text",
        text: m.payload?.text,
      };
    } else if (messageType === "file") {
      payload = {
        ...payload,
        type: "file",
        // fileName: fileName,
        // fileUrl: m.payload?.url,
        files: m.payload?.files || [],
      };
    } else if (messageType === "choice") {
      const messagesFromCurrent = messages.slice(messageIndex + 1);
      const nextButtonSelect = messagesFromCurrent.find((msg) => msg.payload?.type === "button_select");
      const selectedChoice = m?.payload?.choices?.find?.((c) => c?.value === nextButtonSelect?.payload?.payload?.value);
      // const selectedChoice = m?.payload?.choices?.find?.((c) => c?.value === nextMsg?.payload?.payload?.value);
      payload = {
        ...payload,
        type: "choice",
        text: m.payload?.text,
        choices: m.payload?.choices.map((c) => ({
          ...c,
          disabled: true,
          isSelected: c.value === selectedChoice?.value,
        })),
      };
    } else if (messageType === "image") {
      payload = {
        ...payload,
        type: "image",
        files: m.payload?.files || [],
      };
    } else if (messageType === "dynamic_carousel") {
      payload = {
        ...payload,
        type: "dynamic_carousel",
        carouselData: m.payload?.carousel_data,
        position: "center",
      };
    } else if (messageType === "location") {
      payload = {
        ...payload,
        locationInfo: {
          latitude: m.payload?.latitude,
          longitude: m.payload?.longitude,
          name: m.payload?.name,
          address: m.payload?.address,
        },
        type: "location",
      };
    }
  } else if (m.type === "action") {
    const actionType = m.payload?.type?.toLowerCase();
    const actionPayload = m.payload?.payload;

    if (actionType === "input_request" || m.payload?.input_type === "file") {
      // #region Set input component's data
      let data;
      const currentIndex = messages.findIndex((msg) => msg === m);
      let followingSubmitInputRequest;
      for (let i = currentIndex + 1; i < messages.length; i++) {
        if (messages[i].payload?.type === "submit_input_request") {
          followingSubmitInputRequest = messages[i];

          // Iterate through each submit_input_request's field IDs and check if they are included in the input_request field IDs
          // TODO: Backend should be fixed message order.
          if (actionPayload?.input_format?.type === "form") {
            const followingSubmitInputRequestFieldIds = actionPayload?.input_format?.fields
              ? actionPayload?.input_format?.fields.map((f) => f.format.id)
              : [actionPayload?.input_format?.id];
            const dataKeys = Object.keys(followingSubmitInputRequest?.payload?.payload?.data || {});
            const dataKeysIncluded = dataKeys.every((k) => followingSubmitInputRequestFieldIds.includes(k));
            if (!dataKeysIncluded) {
              continue;
            }
          }

          break;
        }
      }
      let cancelled = false;
      if (followingSubmitInputRequest) {
        data = followingSubmitInputRequest.payload?.payload?.data;
        cancelled = followingSubmitInputRequest.payload?.payload && data === null;
      }

      const type = m.payload?.payload?.input_format?.type;

      // #endregion
      payload = {
        ...payload,
        type: "inputRequest",
        fileList: m.files,
        text: m.payload?.msg,
        inputFormat: {
          ...m.payload?.payload?.input_format,
          disabled: true,
          data,
          cancelled,
        },
        position: type === "form" ? "center" : "left",
        disabled: true,
        sender: m.sender,
      };
    } else if (actionType === "submit_input_request") {
      // let data;
      // const currentIndex = messages.findIndex((msg) => msg === m);
      // let previousInputRequest;
      // for (let i = currentIndex; i > 0; i--) {
      //   if (messages[i].payload?.type === "input_request") {
      //     previousInputRequest = messages[i];
      //     break;
      //   }
      // }
    } else if (actionType === "direct_to_ticket") {
      payload = {
        ...payload,
        type: "notification",
        position: "center",
        text: i18next.t("chatbot.actionInfo.ticketOpen"),
      };
    } else if (actionType === "submit_ticket") {
      const uuid = m?.payload?.payload?.ticket_id;

      payload = {
        ...payload,
        type: "custom",
        position: "center",
        render: <TicketCreatedMessage bot={session.bot} project={session.project} uuid={uuid} />,
        // render: function () {
        //   return (
        //     <div className="text-center pr-notification-message">
        //       {i18next.t("chatbot.actionInfo.ticketSaved")}
        //       {uuid && session.project && session.bot && (
        //         <PRLink
        //           newTab
        //           noPrefix
        //           to={`/dashboard/project/${session.project}/bot/${session.bot}/helpdesk/ticket/form/${uuid}/`}
        //           scope={"other"}
        //         >
        //           Click here to view ticket
        //         </PRLink>
        //       )}
        //     </div>
        //   );
        // },
      };
    } else if (actionType === "cancel_ticket") {
      payload = {
        ...payload,
        type: "notification",
        position: "center",
        text: i18next.t("chatbot.actionInfo.ticketCancel"),
      };
    } else if (actionType === "button_select") {
      const selectedChoice = previousMsg?.payload?.choices?.find?.((c) => c?.value === m?.payload?.payload?.value);
      if (!selectedChoice) {
        return;
      }
      payload = {
        ...payload,
        type: "text",
        isChoiceText: true,
        text: selectedChoice.text,
      };
    } else if (actionType === "bot_connect" || actionType === "agent_connect") {
      activeSenderData = actionPayload;
      payload = {
        ...payload,
        type: "notification",
        position: "center",
        text: <JoinedMessage isAgent={actionType === "agent_connect"} messageTime={m.time} name={actionPayload.name} />,
        unformattedText: (
          <JoinedMessage
            unformatted
            isAgent={actionType === "agent_connect"}
            messageTime={m.time}
            name={actionPayload.name}
          />
        ),
      };
    } else if (actionType === "transfer_agent") {
      activeSenderData = actionPayload;
      payload = {
        ...payload,
        type: "notification",
        position: "center",
        text: (
          <JoinedMessage
            isAgent
            isTransfer
            messageTime={m.time}
            name={actionPayload.name}
            note={actionPayload.previous_process_note}
          />
        ),

        unformattedText: (
          <JoinedMessage
            isAgent
            isTransfer
            unformatted
            messageTime={m.time}
            name={actionPayload.name}
            note={actionPayload.previous_process_note}
          />
        ),
      };
    } else if (actionType === "inactivity_timeout") {
      if (m?.payload?.payload?.timeout_level > 1) {
        payload = {
          ...payload,
          type: "notification",
          position: "center",
          text: i18next.t("chatbot.chatSceneTexts.inactivityWarning"),
        };
      }
    } else if (actionType === "direct_to_queue") {
      payload = {
        ...payload,
        type: "notification",
        position: "center",
        text: i18next.t("chatbot.chatSceneTexts.directToQueue").format(actionPayload.queue_type),
      };
    } else if (actionType === "cancel_queue") {
      payload = {
        ...payload,
        type: "notification",
        position: "center",
        text: i18next.t("chatbot.chatSceneTexts.directToQueueCancel").format(m?.payload?.sender_name),
      };
    } else if (actionType === "direct_to_feedback") {
      payload = {
        ...payload,
        type: "notification",
        position: "center",
        text: i18next.t("chatbot.chatSceneTexts.directToFeedback").format(actionPayload.queue_type),
      };
    } else if (m.payload?.action_type === "ticket_created") {
      payload = {
        ...payload,
        type: "notification",
        position: "center",
        text: i18next.t("chatbot.actionInfo.ticketOpen").format(actionPayload.name),
      };
    } else if (actionType === "agent_disconnect" || actionType === "bot_disconnect") {
      payload = {
        ...payload,
        type: "notification",
        position: "center",
        text: i18next.t("chatbot.chatSceneTexts.agentLeavedChat").format(actionPayload.name),
      };
    } else if (actionType === "terminate_session") {
      payload = {
        ...payload,
        type: "notification",
        position: "center",
        text: `${i18next.t("chatbot.chatSceneTexts.sessionClosed")} (${m.payload?.sender_name})`,
      };
    } else if (actionType === "notification") {
      payload = {
        ...payload,
        position: "center",
        type: "notification",
        text: m.payload?.text,
      };
    }
    //TODO: Check alternative for show if user in feedback mode
    // else if (m.payload?.general_question) {
    //
    //   payload = {
    //     ...payload,
    //     type: "notification",
    //     position: "center",
    //     text: m.payload?.general_question,
    //     ...m.payload,
    //   };
    // }
    else {
      return;
    }
  } else if (m.type === "custom") {
    payload = {
      ...payload,
      ...m,
    };
  } else {
    return;
  }
  return payload;
}

export function parseMetadata(metadata, s) {
  const m = metadata;

  let BrowserIcon;
  if (m.browser === "chrome") {
    BrowserIcon = FaChrome;
  } else if (m.browser === "firefox") {
    BrowserIcon = FaFirefox;
  } else if (m.browser === "safari") {
    BrowserIcon = FaSafari;
  } else if (m.browser === "edge" || m.browser === "edge-chromium") {
    BrowserIcon = FaEdge;
  } else if (m.browser === "opera") {
    BrowserIcon = FaOpera;
  } else if (m.browser === "ie") {
    BrowserIcon = FaInternetExplorer;
  } else if (m.browser === "other") {
    BrowserIcon = FaGlobeAmericas;
  }

  let OsIcon;
  if (["Win32", "Win64", "Win16"].includes(m?.platform) || m?.platform?.toLowerCase()?.includes("windows")) {
    OsIcon = FaWindows;
  } else if (m?.platform?.toLowerCase()?.includes("android") || m?.os?.toLowerCase()?.includes("android")) {
    OsIcon = FaAndroid;
  } else if (m?.os?.toLowerCase()?.includes("mac")) {
    OsIcon = FaApple;
  } else if (
    m?.os?.toLowerCase()?.includes("ios") ||
    m?.os?.toLowerCase()?.includes("iphone") ||
    m?.os?.toLowerCase()?.includes("ipad") ||
    m?.os?.toLowerCase()?.includes("ipod")
  ) {
    OsIcon = FaApple;
  } else if (
    ["X11", "Linux i686", "Linux x86_64"].includes(m?.platform) ||
    m?.platform?.toLowerCase()?.includes("linux") ||
    m?.os?.toLowerCase()?.includes("linux")
  ) {
    OsIcon = FaLinux;
  } else {
    OsIcon = FaGlobeAmericas;
  }

  let MobileOrDesktop;
  if (s?.platform === chatbotSessionPlatform.WHAT) {
    MobileOrDesktop = FaWhatsapp;
  } else if (s?.platform === chatbotSessionPlatform.TEA) {
    MobileOrDesktop = BsMicrosoftTeams;
  } else if (s?.platform === chatbotSessionPlatform.INST) {
    MobileOrDesktop = BsInstagram;
  } else if (m?.mobile) {
    MobileOrDesktop = FaMobileAlt;
  } else {
    MobileOrDesktop = FaDesktop;
  }
  return {
    browserIcon: BrowserIcon && <BrowserIcon className="fs-5" title={`${m.browser} ${m.browser_version}`} />,
    osIcon: OsIcon && m.os && <OsIcon className="fs-5" title={`${m.os}`} />,
    platformIcon: MobileOrDesktop && (
      <MobileOrDesktop
        className="fs-5"
        title={(() => {
          if (s?.platform === chatbotSessionPlatform.WHAT) {
            return "WhatsApp";
          } else if (s?.platform === chatbotSessionPlatform.TEA) {
            return "Microsoft Teams";
          } else if (s?.platform === chatbotSessionPlatform.INST) {
            return "Instagram";
          } else if (m.mobile) {
            return "Mobile";
          } else {
            return "Desktop";
          }
        })()}
      />
    ),
  };
}

export function getPlatformIcon(platformName) {
  let PlatformIcon;
  if (platformName === chatbotSessionPlatform.WHAT) {
    PlatformIcon = FaWhatsapp;
  } else if (platformName === chatbotSessionPlatform.TEA) {
    PlatformIcon = BsMicrosoftTeams;
  } else if (platformName === chatbotSessionPlatform.WEB) {
    PlatformIcon = FaDesktop;
  } else if (platformName === chatbotSessionPlatform.INST) {
    PlatformIcon = BsInstagram;
  } else if (platformName === chatbotSessionPlatform.MOB) {
    PlatformIcon = FaMobileAlt;
  }
  return PlatformIcon && <PlatformIcon className="fs-5" title={`${platformName}`} />;
}
